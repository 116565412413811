/*
 * Websocket module.
 * @module websocket
 */

"use strict";

import * as Constants from './constants';
import * as Helpers from './helpers';
import * as Debug from './debug';
import * as Signals from './signals';
import * as Session from './session';

let webSocket;
let connected = false;
let lastPingTime = -1;
let lastPongTime = -1;
let lastMessageTime = -1;
let webSocketId = 0;

// Whether the websocket url in the config has worked
// at least once, in which case we don't try to use alternatives
let urlConfirmed = false;

// Id's for the ping and timeout intervals
/** @type {any} */
let pingInterval = -1;

/** @type {any} */
let timeoutInterval = -1;

function logWithId(message, id=-1, type=0) {
    Debug.log('[' + id + '] ' + message, type);
}

function defaultFailCallback() {
    Signals.quickEmit(
        'internal/show-sub-override-screen',
        _('Waiting for server connection, please wait')
    );
}

/**
 * Initialize the Websocket system
 * @param {function} successCallback - called when initial connection was
 *                                     established
 * @param {function} failCallback - called when connection fails or drops
 */
export function initWebsocket( successCallback = ()=>{}, failCallback = defaultFailCallback){
    webSocketId++;
    let currentWebsocketId = webSocketId;
    logWithId('Attempt to connect to websocket', currentWebsocketId);
    connected = false;

    // If connection doesn't happen in Constants.WEBSOCKET_TIMEOUT milliseconds
    // we try again
    let connectTimeout = window.setTimeout(
        () => {
            if(!connected) {
                killWebsocket();
                logWithId(
                    'Websocket connect timeout reached, trying again',
                    currentWebsocketId
                );
                return initWebsocket(successCallback, failCallback());
            }
        },
        Constants.WEBSOCKET_TIMEOUT
    );

    try {
        // In case we have a previous websocket connection, we make sure to kill
        // it really dead first. We can not rely on the garbage collector to
        // close those properly or clean them up.
        killWebsocket();

        // If the current websocket URL isn't confirmed to be working and this
        // is not our first try to connect, we can try to construct a new url
        // using the current HMI's domain
        if(!urlConfirmed && webSocketId > 1) {
            if(window.CURRENT_CONFIG.webSocketUrl === window.CONFIG.webSocketUrl) {
                const hostname = window.location.hostname;
                const webSocketUrlTemplate = new URL('ws://example.org:4000');
                const restUrlTemplate = new URL('http://example.org:4000/api/v1/');

                webSocketUrlTemplate.hostname = hostname;
                restUrlTemplate.hostname = hostname;
                webSocketUrlTemplate.port = Constants.DEFAULT_WEBSOCKET_PORT.toString();
                restUrlTemplate.port = Constants.DEFAULT_REST_API_PORT.toString();

                window.CURRENT_CONFIG.webSocketUrl = webSocketUrlTemplate.toString();
                window.CURRENT_CONFIG.restUrl = restUrlTemplate.toString();
            } else {
                window.CURRENT_CONFIG.webSocketUrl = window.CONFIG.webSocketUrl;
                window.CURRENT_CONFIG.restUrl = window.CONFIG.restUrl;
            }
        }

        webSocket = new WebSocket(
            window.CURRENT_CONFIG.webSocketUrl +
                '?uuid=' + Session.getUuid()
        );

        webSocket.onopen = () => {
            onConnect();
            urlConfirmed = true;
            successCallback();
            window.clearTimeout(connectTimeout);
        };

        webSocket.onmessage = (event) => {
            // If we receive a message but we don't know yet that we're
            // connected again, we're calling the successCallback nonetheless
            if(!connected) {
                connected = true;
                urlConfirmed = true;
                successCallback();
            }
            onMessageArrived(event.data, currentWebsocketId);
        };

        webSocket.onclose = (event, force=false) => {
            if(connected !== false || force === true) {

                logWithId(
                    'Websocket connection closed, reattempting connect',
                    currentWebsocketId
                );
                Debug.log(event);
                connected = false;
                retryConnect(successCallback, failCallback);
                failCallback('Failed to reconnect to websocket after closing');
            }
        };

        webSocket.onerror = (event) => {
            logWithId(
                'Websocket error detected, running fail callback',
                currentWebsocketId,
                3
            );
            Debug.log(event, 0, false);
            connected = false;
            failCallback();
        };

        // NB: ontimeout is not a default webSocket method, I have added this
        // so we can call this from our timeout handler and we keep all our
        // websocket connection handling in one place
        webSocket.ontimeout = (event) => {
            connected = false;
            logWithId(
                'Websocket connection timed out, reattempting connect',
                currentWebsocketId,
                1
            );
            Debug.log(event, 0, false);
            webSocket.onclose(Helpers.createNewEvent('websocket-timeout'), true);
        };

        setupIntervals(currentWebsocketId);
    } catch(err) {
        if(connected !== false) {
            logWithId(
                'Websocket connection failed, reattempting connect',
                currentWebsocketId,
                3
            );
            Debug.log(err, 3);
            connected = false;
            retryConnect(successCallback, failCallback);
            failCallback(err);
        }
    }
}

/**
 * Closes the current websocket connection and nullifies all it's event handlers.
 */
function killWebsocket() {
    if(webSocket !== null && typeof webSocket !== 'undefined') {
        let nop = () => {};
        webSocket.onClose = nop;
        webSocket.onerror = nop;
        webSocket.onMessage = nop;
        webSocket.ontimeout = nop;
        webSocket.close();
        webSocket = null;
    }
}

/**
 * Setup the interval system for sending ping packets and checking websocket
 * timeouts
 */
function setupIntervals(currentWebsocketId) {
    if(pingInterval !== -1) {
        clearInterval(pingInterval);
        pingInterval = -1;
    }
    if(timeoutInterval !== -1) {
        clearInterval(timeoutInterval);
        pingInterval = -1;
    }

    pingInterval = setInterval(() => sendPing(currentWebsocketId), Constants.PING_INTERVAL);
    timeoutInterval = setInterval(checkWebsocketTimeout, Constants.WEBSOCKET_TIMEOUT);
}

function retryConnect(successCallback, failCallback) {
    Debug.log('Trying to reconnect to Websocket', 0);
    window.setTimeout(() => {
        initWebsocket(successCallback, failCallback);
    }, 5000);
}

/**
 * @returns {boolean} whether we have an active Websocket connection
 */
export function isConnected(){
    return connected;
}

function sendPing(currentWebsocketId) {
    if(connected) {
        send({tag: 'hmi/ping'});
    }
}

/**
 * Interval handler function for checking whether a websocket timeout has
 * occured.
 */
function checkWebsocketTimeout() {
    // If we have never received a message then we don't check for a timeout.
    // Presumably websocket setup has never been completed.
    if(lastMessageTime === -1) {
        return;
    }

    const now = Date.now();
    if((now - lastMessageTime) > Constants.WEBSOCKET_TIMEOUT) {
        // TIMEOUT has occured
        if(webSocket && webSocket.hasOwnProperty('ontimeout')) {
            webSocket.ontimeout();
        }
    }
}


/**
 * Internal function called whenever a connection has been established
 */
function onConnect() {
    Debug.log('Successfully connected to websocket');
    connected = true;
    Signals.reSubscribe();
}

/**
 * Internal handler for message arrival. Parses the JSON and redirects it to a
 * listener if one has been designated for this topic.
 * @param {string} message
 */
function onMessageArrived(message, websocketId) {
    /*
     * Example content of a message:
     * {
     *     "tag": "device/parameter",
     *     "data": {
     *        "device": "1532000000A1114210",
     *        "parameter": "PARAM_QID_CM_TINY_DEVICE_NAME",
     *        "value": "0d02h50m37s"
     *     }
     *  }
     */
    lastMessageTime = Date.now();
    let parsedMessage = JSON.parse(message);

    if(handlePingPong(parsedMessage, webSocketId)) {
        return;
    }

    Signals.emit(parsedMessage);
}

/**
 * Handler for ping/pong messages
 * @param {object} msg
 * @param {number} webSocketId - optional id for logging
 * @returns {boolean} - whether this was a ping pong message
 */
function handlePingPong(msg, webSocketId=-1) {
    if(
        !msg.hasOwnProperty('tag') || (
            msg.tag !== 'manager/ping'  &&
            msg.tag !== 'manager/pong'
        )
    ) {
        return false;
    }

    if(msg.tag === 'manager/ping') {
        lastPingTime = Date.now();
        send({tag: 'hmi/pong'});
    } else if(msg.tag === 'manager/pong') {
        lastPongTime = Date.now();
        Signals.quickEmit('internal/hide-sub-override-screen');
    }
}

/**
 * Send a given message to the websocket.
 * @param {object} message - this is stringified for you
 */
export function send(message){
    if(connected) {
        webSocket.send(JSON.stringify(message));
    }
}

/**
 * Send a raw message to the websocket.
 * @param {String} rawMessage
 */
export function sendRaw(rawMessage){
    if(connected) {
        webSocket.send(rawMessage);
    }
}
